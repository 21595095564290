export const MenuItems = [
	{
		title: 'Home',
		url: '/',
		cName: 'nav-links'
	},
	{
		title: 'Music',
		url: '/Music',
		cName: 'nav-links'
	},
	{
		title: 'Video Games',
		url: '/Video_Games',
		cName: 'nav-links'
	},
	{
		title: 'Code Projects',
		url: '/Code_Projects',
		cName: 'nav-links'
	},
	{
		title: 'Web Apps',
		url: '/Web_Apps',
		cName: 'nav-links'
	},
	{
		title: 'Misc',
		url: '/Misc',
		cName: 'nav-links'
	}
	
]